import React from 'react';
import Typography from '@material-ui/core/Typography';
import DoneOutline from '@material-ui/icons/DoneOutline';
import {Grid} from '@material-ui/core';
const SavingModal = ({ header, body }) => {


    return (
        <div>
            <Grid container justify = "center" style={{padding:"20px"}}>
                <DoneOutline className="center" style={{fontSize:"40px"}} color={"secondary"}/>
            </Grid>
            
            <Typography
                component="h4"
                className="center"
                variant="h4" gutterBottom color={"secondary"}>
                {header}
            </Typography>
            <Typography
                component="h5"
                className="center"
                style={{padding:"40px"}}
                variant="h5" gutterBottom color={"secondary"}>
                {body}
            </Typography>

        </div>
    )
}


export default SavingModal;