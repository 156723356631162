const dev = {
    BUCKET: "bookingdev.workiz.com",
    SUBMIT_URL: "https://dev.workiz.com/api/Booking/submitBooking/",
    API_URL: "https://us-central1-decoded-agency-207514.cloudfunctions.net/bookingdev/",
    BOOKING_V2_URL: "https://online-booking-staging.workiz.com"
};

const prod = {
    BUCKET: "booking.workiz.com",
    SUBMIT_URL: "https://app.workiz.com/api/Booking/submitBooking/",
    API_URL: "https://us-central1-decoded-agency-207514.cloudfunctions.net/booking/",
    BOOKING_V2_URL: "https://online-booking.workiz.com"
};

const staging = {
    BUCKET: "bookingstaging.workiz.com",
    SUBMIT_URL: "https://staging.workiz.com/api/Booking/submitBooking/",
    API_URL: "https://us-central1-decoded-agency-207514.cloudfunctions.net/bookingstage/",
    BOOKING_V2_URL: "https://online-booking-staging.workiz.com"
};

const config = process.env.REACT_APP_STAGE === 'prod' ? prod :
    (process.env.REACT_APP_STAGE === 'staging' ? staging : dev);

export default config;