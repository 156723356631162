import React, { Component } from 'react';
import moment from 'moment';
import {Button} from '@material-ui/core';
import ChevronLeft from '@material-ui/icons/ChevronLeft';

class ChooseTime extends Component {
    state = {
        date: "",
        timeSlots: [9, 10.5, 12, 16.25],
        slotLength: 1.5
    };

    componentDidMount() {
        this.setProps(this.props);
    }

    componentWillReceiveProps(nextProps) {
        this.setProps(nextProps);
    }

    setProps = ({ day, accountSettings, slots }) => {
        if (!(slots && slots.length)) {
            const timeSlots = [];
            slots = [];
            const d = moment(day, "DD-MM-YY").isoWeekday() - 1;
            if (accountSettings.working_times_obj[d]) {
                const lastSlot = accountSettings.working_times_obj[d].lastSlot || 0;
                const startTime = accountSettings.working_times_obj[d].start || 0;
                for (let i = 0; i < lastSlot; i++) {
                    timeSlots.push((i * accountSettings.slot_length) + startTime);
                }
                slots = timeSlots;
            }
        }
        if (day == accountSettings.scheduleOffsetMoment.format("DD-MM-YY")) {
            const now = accountSettings.scheduleOffsetMoment.hour() + (accountSettings.scheduleOffsetMoment.minute() / 60);
            slots = slots.filter(item => item > now);
        }
        this.setState({ timeSlots: slots, slotLength: accountSettings.slot_length });
        return;
    }



    x = false;
    onChangeDate = (date) => {
        this.setState({ date })
        this.x = true;
        this.props.onDone(date);
    }
    dateDisabled = date => {

        if (date < new Date()) {
            return true;
        }
        return false;
    }
    render() {
        const { timeSlots, slotLength } = this.state

        return (
            <div>
                {timeSlots.map(item => {
                    const t1 = moment().hour(0).minute(0).add(item, 'hours')
                    const t2 = moment().hour(0).minute(0).add(item + slotLength, 'hours')
                    return (
                        <div
                            key={item}
                            className={`btn-card ${item == this.props.val ? 'picked' : ''}`}
                            onClick={() => this.onChangeDate(item)}
                        >
                            {t1.format('h:mm a') + ' - ' + t2.format('h:mm a')}
                        </div>
                    );
                })}
                {
                    !timeSlots.length &&
                    <div
                        color="primary"
                        style={{marginTop: 20, marginBottom: 20}}
                    >
                        There are no available appointments for this date. <br /><br />
                        Please choose another date and try again
                    </div>
                }
                <Button variant="outlined" onClick={this.props.goBack}>
                    <ChevronLeft/>
                    Go back
                </Button>
            </div>
        );
    }
}

export default ChooseTime;
