import React, { Component } from 'react';
import {
    MenuItem,
    TextField,
    Select,
    FormControl,
    InputLabel,
    FormControlLabel,
    Checkbox,
    FormHelperText,
    Button,
    CircularProgress,
} from '@material-ui/core';
import ChevronLeft from '@material-ui/icons/ChevronLeft';


const validationRegex = {
    "phone": {
        regex: /^(1\s|1|)?((\(\d{3}\))|\d{3})(\-|\s)?(\d{3})(\-|\s)?(\d{4})$/,
        alert: "Invalid phone number"
    },
    "email": {
        regex: /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i,
        alert: "Invalid email address"
    },
    "int": {
        regex: /^[\-\+]?(([0-9]+)([\.,]([0-9]+))?|([\.,]([0-9]+))?)$/,
        alert: "Invalid number"
    }

}

class ClientDetails extends Component {
    state = {
        userFields: {}
    }

    componentWillReceiveProps(nextProps, prevProps) {
        this.setFields(nextProps, { userFields: "" })
    }

    componentDidMount() {
        this.setFields(this.props, this.props)
    }

    setFields = ({ userFields, accountSettings }, prev) => {
        if (userFields != prev.userFields || !Object.keys(this.state.userFields || {}).length) {
            const _userFields = JSON.parse(userFields || "{}");
            const tempPositions = [];
            // Avoid losing fields because of double positions
            for (let prop in _userFields) { 
                const _item = _userFields[prop];
                if(tempPositions[_item.position]) {
                    _item.position = tempPositions.length;
                }
                tempPositions[_item.position] = true;
            }
            this.setState({ userFields: _userFields })
        }
        let jobtypes = accountSettings.jobtypes || [];
        if (typeof jobtypes === 'string') {
            jobtypes = JSON.parse(jobtypes);
        }
        this.setState({ jobtypes });
    }

    validateForm = () => {
        let valid;
        const validations = {};
        for (let prop in this.state.userFields) {
            valid = this.validateField(this.state.userFields[prop], this.props.userDetails[prop] || "");
            if (valid != "") {
                validations[prop + "_valid"] = valid;
            }
        }
        if (Object.keys(validations).length) {
            this.setState({ ...validations });
            return false;
        }
        return true;
    }

    validateField = (field, val) => {
        if (!val && field.required) {
            return "This field is required";
        }
        if (val && field.type && validationRegex[field.type]) {
            const tst = validationRegex[field.type];
            return tst.regex.test(val) ? "" : tst.alert;
        }
        return "";
    }

    handleChange = (field, key, event, label) => {
        const val = event.target.value;
        const validField = this.validateField(field, val);
        this.props.setVal(key, val, label);
        this.setState({ [key + "_valid"]: validField })
    }

    send = () => {
        if (this.validateForm()) {
            if (typeof this.props.sendForm === "function") {
                this.setState({ loading: true })
                this.props.sendForm();
            }
        }
    }


    state = { validPhone: true, validEmail: true };
    render() {
        const { userFields } = this.state;
        const addresFields = ["city","state","zipcode"];
        const withPadding = ["city","state","zipcode"];
       
        const jsx = [];
        for (let prop in userFields) {
            const fullWidth = addresFields.indexOf(prop) == -1;
            const addressField = withPadding.indexOf(prop) == -1 ? "" : "_addressField";
            switch (userFields[prop].type) {
                case "select":
                    const options = (userFields[prop].options || "").split(";");
                    jsx[userFields[prop].position] = (
                        <FormControl
                            required={userFields[prop].required ? true : false}
                            margin="dense"
                            fullWidth={true} key={prop}
                            className="margin-top"
                            error={(this.state[prop + "_valid"] || "") != ""}
                        >
                            <InputLabel htmlFor={prop}>Choose {userFields[prop].label}</InputLabel>
                            <Select
                                value={this.props.userDetails[prop] || ""}
                                onChange={(e) => this.handleChange(userFields[prop], prop, e, userFields[prop].label)}
                                name={prop}
                                fullWidth={true}
                                margin="dense"

                                id={prop}
                            >
                                <MenuItem value="">
                                    <em>Choose {userFields[prop].label}</em>
                                </MenuItem>
                                {
                                    options.map(item => (
                                        <MenuItem value={item} key={item}>{item}</MenuItem>
                                    ))
                                }
                            </Select>
                            <FormHelperText>{this.state[prop + "_valid"] || ""}</FormHelperText>
                        </FormControl>
                    );
                    break;

                case "checkbox":
                    jsx[userFields[prop].position] = (
                        <FormControlLabel
                            margin="dense"
                            key={prop}
                            control={
                                <Checkbox
                                    checked={this.props.userDetails[prop] || false}
                                    onChange={e => this.props.setVal(prop, e.target.checked, userFields[prop].label)}
                                    value={prop}
                                    color="primary"
                                />
                            }
                            label={userFields[prop].label}
                        />
                    );
                    break;
                case "datetime":
                    jsx[userFields[prop].position] = (
                        <TextField
                            required={userFields[prop].required ? true : false}
                            key={prop}
                            type={"date"}
                            value={this.props.userDetails[prop] || ""}
                            name={prop}
                            fullWidth={true}
                            margin="dense"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            error={(this.state[prop + "_valid"] || "") != ""}
                            helperText={this.state[prop + "_valid"] || ""}
                            label={userFields[prop].label || prop}
                            onChange={(e) => this.handleChange(userFields[prop], prop, e, userFields[prop].label)} />
                    );
                    break;
                default:
                    jsx[userFields[prop].position] = (
                        <TextField
                            className={addressField}
                            required={userFields[prop].required ? true : false}
                            key={prop}
                            type={userFields[prop].type == "datetime" ? "date" : "text"}
                            value={this.props.userDetails[prop] || ""}
                            name={prop}
                            multiline={userFields[prop].type == "textarea"}
                            fullWidth={fullWidth}
                            margin="normal"
                            error={(this.state[prop + "_valid"] || "") != ""}
                            helperText={this.state[prop + "_valid"] || ""}
                            label={userFields[prop].label || prop}
                            onChange={(e) => this.handleChange(userFields[prop], prop, e, userFields[prop].label)} />
                    );
                    break;
            }
        }
        const jobProp = "job_type";
        return (

            <section>
                {(this.state.jobtypes || []).length > 0 && < FormControl
                    margin="dense"
                    fullWidth={true} key={jobProp}
                    className="margin-top"
                    error={(this.state[jobProp + "_valid"] || "") != ""}
                >
                    <InputLabel htmlFor={jobProp}>What service would you require?</InputLabel>
                    <Select
                        value={this.props.userDetails[jobProp] || ""}
                        onChange={(e, menuitem) => { 
                            this.props.setVal(jobProp, e.target.value, '', () => {
                                this.props.setVal('job_type_name', menuitem.props.title, '')
                            })
                        }}
                        name={jobProp}
                        fullWidth={true}
                        margin="dense"

                        id={jobProp}
                    >
                        <MenuItem value="">
                            <em>Other</em>
                        </MenuItem>
                        {
                            (this.state.jobtypes || []).map(item => (
                                <MenuItem value={item.id} title={item.type_name} key={item.id}>{item.type_name}</MenuItem>
                            ))
                        }
                    </Select>
                </FormControl>}
                {jsx}
                <Button variant="contained" color="primary"
                    fullWidth={true}
                    style={{
                        marginTop: 40,
                    }}
                    disabled={!this.props.enableFinish || this.state.loading}
                    onClick={this.send}
                >
                    {this.state.loading ? <CircularProgress /> :
                        'Schedule'}
                </Button>
                <div className='margin-top'>By booking a job, you agree to receive SMSs regarding the booking requested</div>
                <Button variant="outlined" onClick={this.props.goBack} style={{marginTop:"10px"}}>
                    <ChevronLeft/>
                    Go back
                </Button>
            </section >
        );
    }
}

export default ClientDetails;
