import launchDarklyTokens from "../constants/launchDarklyTokens";
import * as LDClient from 'launchdarkly-js-client-sdk';

let client;

export const initFeatureFlags = async (userData) => {
    try {
        const { accountId, ...custom } = getAttributes(userData);
        const user = { key: accountId.toString(), custom };
        const clientSideId = getClientSideId();
        client = LDClient.initialize(clientSideId, user, {});
        client.on('ready', () => console.log("launchDarkly is ready!!!!!!"))
    }
    catch (e) {
        console.error(e);
    }
}

export function isEnabled(flagName, defaultValue) {
    let value;
    try {
        if(client) value = client.variation(flagName, defaultValue);
    }
    catch (e) {
        console.error(e);
        value = defaultValue;
    }
    return value;
}

const getClientSideId = () => {
    const launchDarklyEnvToken = launchDarklyTokens[process.env.REACT_APP_STAGE];
    return (launchDarklyEnvToken !== null && launchDarklyEnvToken !== undefined) ? launchDarklyEnvToken : launchDarklyTokens["dev"];
}

const getAttributes = (userData) => {
    return {
        accountId: Number(userData.account_id),
        email: userData.email
    }
}