import React, { Component } from 'react';
import moment from 'moment';
import {
    Button,
    LinearProgress
} from '@material-ui/core';

class ChooseDate extends Component {
    state = { page: 0, loading: false };
    onChangeDate = (date) => {
        this.setState({ date })
        this.props.onDone(date);
    }
    dateDisabled = date => {
        if (date < new Date()) {
            return true;
        }
        return false;
    }

    componentWillReceiveProps(nextProps, prvProps) {
        if (nextProps.paging != prvProps.paging && this.state.loading) {
            this.setState({ loading: false })
        }
    }

    renderDates = () => {
        const { accountSettings, byDay } = this.props;
        const jsxDates = [];
        const picked = moment(this.props.val).format('DD-MM-YY');
        const todayAt0Hour = moment().set({hour:0,minute:0,second:0,millisecond:0});
        const firstDay = accountSettings.scheduleOffsetMoment.diff(todayAt0Hour, "day");
        for (let i = firstDay; i < this.props.pagingSize * (this.props.paging + 1); i++) {
            let tm = moment().add(i, 'days');
            let formattedTM = tm.format('DD-MM-YY');
            let day = (tm.day() || 7) - 1;
            let disabled = (!(this.props.slots[formattedTM] || [1]).length) ||
                (byDay[formattedTM] || 0) >= accountSettings.availble_techs ||
                (!this.props.accountSettings.working_times_obj[day]);
            jsxDates.push(
                <div
                    key={i + this.state.page}
                    className={`btn-card ${disabled ? 'disabled' : ''} ${tm.format('DD-MM-YY') == picked ? 'picked' : ''}`}
                    onClick={() => this.onChangeDate(tm.clone())}
                >
                    {
                        tm.calendar(null, {
                            sameDay: '[Today,] MMM Do',
                            nextDay: '[Tomorrow,] MMM Do',
                            nextWeek: 'dddd[,] MMM Do',
                            lastDay: '[Yesterday,] MMM Do',
                            lastWeek: '[Last] dddd[,] MMM Do',
                            sameElse: 'dddd[,] MMM Do'
                        })
                    }
                </div>
            );
        }

        return jsxDates;
    }

    render() {
        return (
            <div>
                {this.renderDates()}
                {!this.state.loading ?
                    <Button
                        onClick={() => {
                            this.props.loadSlots();
                            this.setState({ loading: true }, () => setTimeout(() => {
                                if (this.state.loading) {
                                    this.setState({ loading: false })
                                }
                            }, 10 * 1000))
                        }}
                        variant="outlined"
                        color="secondary"
                        fullWidth={true}
                        style={{ marginTop: 10 }}
                    >
                        Load more...
                    </Button>
                    :
                    <LinearProgress style={{ marginTop: 40 }} />
                }
            </div>
        );
    }
}

export default ChooseDate;
