export const decodeQuery = (url) => {
    url = decodeURI(url);
    if (typeof url === 'string') {
        let params = url.split('?');
        if (params.length <= 1) return {}; // No query provided

        let eachParamsArr = params[1].split('&');
        let obj = {};
        if (eachParamsArr && eachParamsArr.length) {
            eachParamsArr.map(param => {
                let keyValuePair = param.split('=')
                let key = keyValuePair[0];
                let value = keyValuePair[1];
                obj[key] = value;
            })
        }
        return obj;
    }
}