import React, { Component } from 'react';
import './App.css';
import ClientDetails from './containers/ClientDetails';
import moment from 'moment';
import axios from 'axios'
import * as urlHelper from './helpers/urlHelper';
import * as amplitudeHelper from './helpers/amplitudeHelper'
import amplitude from "amplitude-js";
import {
  AppBar,
  Card,
  Step,
  Stepper,
  CircularProgress,
  StepButton,
  Slide,
  Avatar,
  Typography
} from '@material-ui/core';
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles'
import ChooseDate from './containers/ChooseDate';
import ChooseTime from './containers/ChooseTime';
import SavingModal from './containers/SavingModal';
import config from './config';
import { useDarker } from './helpers/colorsHelper';
import { initFeatureFlags, isEnabled } from './helpers/featureFlags';

const API_URL = config.API_URL;
const BOOKING_V2_URL = config.BOOKING_V2_URL;
const PAGING_SIZE = 7;
const UNSET_OLD_BOOKING_FF_NAME = "unset-old-booking";

amplitudeHelper.initAmplitude()

const hours = { start: 0, end: 24 };
const defaultHours = {
  0: { ...hours },
  1: { ...hours },
  2: { ...hours },
  3: { ...hours },
  4: { ...hours },
  5: { ...hours },
  6: { ...hours },
}

const clientParamsMapping = {
  email_address: "email_address", 
  phone: "primary_phone"
}

const defaultSettings = {
  color: "#3b4b52",
  slot_length: 1,
  user_fields: '{"user_name":{"label":"Your Name","type":"text","required":true,"position":0},"email_address":{"label":"Email Address","type":"email","required":true,"position":1}}',
  working_times: JSON.stringify(defaultHours),
  title: "Schedule a Job",
  available_based: 0,
}

const theme = (primary) => {
  return createMuiTheme({
    typography: {
      useNextVariants: true,
    },

    palette: {
      primary: { main: primary || '#3b4b52' },
      secondary: { main: useDarker(primary || '#3b4b52') },
      error: { main: '#e35a36' },
      contrastThreshold: 3,
    },
  })
};

class App extends Component {
  renderConfirmationString() {
    const spanStyle = { color: useDarker(this.state.themeColor || '#3b4b52') }
    const h3Style = { textAlign: 'center', color: '#666', lineHeight: 1.5, padding: '0 10px', fontWeight: 500, margin: "0px" };
    switch (this.state.activeStep) {
      case 0:
        return (
          <h3 style={h3Style} key="confirmation">
            <span className="auto-margin">Select A Date</span>
          </h3>
        )
        break;
      case 1:
        return (
          <h3 style={h3Style} key="confirmation">
            <span style={spanStyle}>{moment(this.state.appointmentDate).format('ddd[,] MMM Do')}</span><br />
            <span className="auto-margin">What time?</span>
          </h3>
        )
        break;
      case 2:
        return (
          <h3 style={h3Style} key="confirmation">
            <span style={spanStyle}>{moment(this.state.appointmentDate).format('ddd[,] MMM Do')} At {moment().hour(0).minute(0).add(this.state.appointmentSlot, 'hours').format('h:mm a')}</span><br />
            <span className="auto-margin">Add your details</span>
          </h3>
        )
        break;

      default:
        return null;
        break;
    }
    return null;
  }

  state = {
    activeStep: 0,
    completedSteps: 0,
    slotLength: 1,
    appointmentDate: null,
    takenSlots: {},
    dailySlots: {},
    userDetails: {},
    paging: -1
  };

  getStepContent = () => {
    if (this.state.paging < 0) {
      return (
        <div className="center margin-top">
          <CircularProgress />
        </div>
      )
    }

    return (
      <div>
        <Slide direction="left" in={this.state.activeStep == 0} mountOnEnter unmountOnExit>
          <ChooseDate
            loadSlots={this.loadSlots}
            pagingSize={PAGING_SIZE}
            paging={this.state.paging}
            accountSettings={this.state.accountSettings || {}}
            slots={this.state.dailySlots || {}}
            takenSlots={this.state.takenSlots}
            val={this.state.appointmentDate || new Date()}
            byDay={this.state.byDay || {}}
            onDone={val => this.setState({ activeStep: 1, confirmationTextVisible: true, appointmentDate: val, appointmentSlot: "-1" })}
          />
        </Slide>

        <Slide direction="left" in={this.state.activeStep == 1} mountOnEnter unmountOnExit>
          <ChooseTime
            goBack={() => this.setState({ activeStep: 0 })}
            takenSlots={this.state.takenSlots}
            accountSettings={this.state.accountSettings || {}}
            slots={this.state.dailySlots[moment(this.state.appointmentDate || new Date()).format('DD-MM-YY')] || []}
            val={this.state.appointmentSlot || "-1"}
            day={moment(this.state.appointmentDate || new Date()).format('DD-MM-YY')}
            slotLength={this.state.slotLength}
            onDone={(appointmentSlot) => this.setState({ activeStep: 2, appointmentSlot })}
          />
        </Slide>

        <Slide direction="left" in={this.state.activeStep == 2} mountOnEnter unmountOnExit>
          <ClientDetails
            goBack={() => this.setState({ activeStep: 1 })}
            accountSettings={this.state.accountSettings || {}}
            sendForm={this.sendForm}
            enableFinish={this.state.appointmentSlot > -1 && this.state.appointmentSlot != "-1" && this.state.appointmentDate != null}
            userDetails={this.state.userDetails}
            setVal={this.setVal}
            userFields={(this.state.accountSettings || {}).user_fields || "{}"}
          />
        </Slide>
      </div>
    )


  }

  setVal = (key, val, label, callback) => {
    const customFields = this.state.customFields || {};
    if (/^[f]\d/i.test(key)) {
      customFields[label] = val;
    }
    this.setState({ userDetails: { ...this.state.userDetails, [key]: val }, customFields }, callback)
  }

  sendForm = () => {
    const userName = (this.state.userDetails.user_name || "").split(" ", 2);
    const userDetails = Object.assign({}, this.state.userDetails);
    delete userDetails.user_name;
    const job_date = moment(this.state.appointmentDate)
      .hour(0).minute(0).add(this.state.appointmentSlot, 'hours');

    axios.post(config.SUBMIT_URL, {
      ac: this.state.ac,
      uc: this.state.uc || "-1",
      ad_group: this.state.accountSettings.ad_group || "-1",
      action: 'submit_booking',
      customFields: this.state.customFields || {},
      userDetails: {
        ...userDetails,
        first_name: userName[0],
        last_name: (userName[1] || ""),
        job_date: job_date.format("M/DD/Y H:mm"),
        job_end_date: job_date.add(this.state.slotLength, "hours").format("M/DD/Y H:mm"),
        ...(this.state.accountSettings.ad_group_name ? { ad_group_name: this.state.accountSettings.ad_group_name } : {})
      }
    },
      {
        headers: {
          'Content-type': 'application/form-url-encode'
        }
      }
    ).then(res => {
      if (res.data.flag) {
        if (this.state.accountSettings.thank_you_url) {
          try {
            amplitude.getInstance().logEvent("addedJob",{origin:'new_job'});
            if(window.top === window.self) {
              window.location.href = this.state.accountSettings.thank_you_url
            } else {
              window.top.location.href = this.state.accountSettings.thank_you_url
            }
          }
          catch {
            this.setState({ formSent: 1 });
          }
        } else {
          this.setState({ formSent: 1 });
        }
      } else {
        this.setState({ formSent: 2 });
      }
    }).catch(() => {
      this.setState({ formSent: 2 });
    });
  }

  getWorkingHours = (accountSettings) => {
    if (accountSettings.working_times_obj) {
      return accountSettings.working_times_obj;
    }
    let working_times_obj = JSON.parse(accountSettings.working_times || "{}");
    working_times_obj = working_times_obj || {};
    if (!Object.keys(working_times_obj).length) {
      working_times_obj = { ...defaultHours };
    }
    for (let i in working_times_obj) {
      working_times_obj[i] = working_times_obj[i] || {};
      working_times_obj[i].lastSlot = Math.floor(((working_times_obj[i].end || 0) - (working_times_obj[i].start || 0)) / accountSettings.slot_length);
    }
    return working_times_obj;
  }

  loadSlots = () => {
    let { ac, uc, accountSettings, paging, dailySlots } = this.state
    const working_times_obj = this.getWorkingHours(accountSettings);
    paging++;
    if(paging == 0 && accountSettings.schedule_offset == 7 * 24) {
      paging = 1;
    }

    axios.post(API_URL, {
      ac, uc,
      action: 'getSlots',
      slotLength: accountSettings.slot_length,
      startAt: accountSettings.working_start_time,
      paging: paging * PAGING_SIZE,
      available_based: accountSettings.available_based
    }).then(res => {
      if (res.data.flag && Array.isArray(res.data.data)) {
        const obj = {};
        const data = res.data.data;
        data.forEach(item => {
          obj[item.job_day] = obj[item.job_day] || {};
          obj[item.job_day][item.slot_key] = item.counter;
        });
        dailySlots = this.calculateSlots(accountSettings, obj, working_times_obj, dailySlots);

      // Allow Double Booking
      if (accountSettings.available_based == 0) {

        this.setState({
          takenSlots: {}, 
          byDay: {},
          dailySlots, 
          paging,
          accountSettings: { ...accountSettings, working_times_obj }
        });
      }
      else {
        this.setState({
          takenSlots: obj,
          dailySlots, paging,
          accountSettings: { ...accountSettings, working_times_obj }
        });
      }
      }
    });
  }


  componentDidMount() {
    const query = window.location.search;
    const params = urlHelper.decodeQuery(query);
    this.setParamsClientValues(params);
    const ac = params.ac;
    const uc = params.uc;
    const urlAdgroup = params.ad_group;
    const thankParamsObj = { ...params };
    delete thankParamsObj.ac;
    delete thankParamsObj.uc;
    delete thankParamsObj.ad_group;
    let thankParams = [];
    for (const prop in thankParamsObj) {
      thankParams.push(prop + "=" + thankParamsObj[prop]);
    }
    thankParams = "?" + thankParams.join("&");
    this.setState({ themeColor: "#3b4b52" });
    axios.post(API_URL, { ac, uc, action: 'getSettings' }).then(res => {
      if (res.data.flag && res.data.data) {
        let accountSettings = res.data.data[0] || {};
        for (let idx in accountSettings) {
          if (accountSettings[idx] === null) {
            accountSettings[idx] = defaultSettings[idx];
          }
        }
        initFeatureFlags(accountSettings);
        accountSettings = {
          ...defaultSettings,
          ...accountSettings,
          scheduleOffsetMoment: moment().add(parseInt(accountSettings.schedule_offset) || 0, "hours"),
          thank_you_url: accountSettings.thank_you_url ? accountSettings.thank_you_url + thankParams : undefined,
          ad_group: accountSettings.ad_group || -1,
          ad_group_name: urlAdgroup || "",
        };
        if (uc && accountSettings.available_based == 1) {
          accountSettings.availble_techs = 1;
        }
        this.setState({
          themeColor: accountSettings.color || "#3b4b52",
          ac, uc, accountSettings, slotLength: accountSettings.slot_length
        }, this.loadSlots);
      }
    });
  }

  decodeParam = (param) => {
    if(!param) return '';
    return decodeURIComponent(param).replace(/\+/g, ' ')
  }

  setParamsClientValues = (params) => {
    const userDetails = {};
    if(params.first_name || params.last_name) {
      userDetails.user_name = `${this.decodeParam(params.first_name) || ""} ${this.decodeParam(params.last_name) || ""}`
    }
    for(let param in params) {
      if(clientParamsMapping[param]) {
        userDetails[clientParamsMapping[param]] = this.decodeParam(params[param]);
      }
    }
    this.setState({userDetails})
  }


  calculateSlots = (accountSettings, takenSlots, working_times_obj, dailySlots) => {
    dailySlots = dailySlots || {};
    let dailyTaken;
    let day;

    for (let prop in takenSlots) { // Calculate each day
      dailySlots[prop] = [];
      dailyTaken = takenSlots[prop] || {};
      day = (moment(prop, "DD-MM-YY").day() || 7) - 1;
      for (let i = 0; i < ((working_times_obj[day] || {}).lastSlot || 0); i++) {
        if (!(dailyTaken[i] >= (accountSettings.available_based === 0 ? 100 : accountSettings.availble_techs))) {
          dailySlots[prop].push((i * accountSettings.slot_length) + ((working_times_obj[day] || {}).start || 0));
        }
      }
    }

    return dailySlots;
  }

  renderAllSteps = () => {
    return [
      <Stepper
        key="stepper"
        steps={3}
        position="static"
        activeStep={this.state.activeStep}
        alternativeLabel
      >
        <Step className="stepFather" >
          <StepButton
            className="stepButton"
            completed={this.state.appointmentDate != null && this.state.activeStep > 0}
            onClick={() => this.setState({ activeStep: 0 })}>
            Select a date
          </StepButton>
        </Step>
        <Step className="stepFather" >
          <StepButton
            className="stepButton"
            completed={this.state.appointmentSlot > -1 && this.state.appointmentSlot != "-1" && this.state.appointmentDate != null && this.state.activeStep > 1}
            onClick={() => this.setState({ activeStep: 1, confirmationTextVisible: true })}>
            Select a time slot
          </StepButton>
        </Step>
        <Step className="stepFather" >
          <StepButton
            className="stepButton"
            onClick={() => this.setState({ activeStep: 2 })}>
            Fill in your details
          </StepButton>
        </Step>
      </Stepper>,
      this.renderConfirmationString(),
      <div key="div" style={{ padding: '10px 45px 30px' }} >
        {this.getStepContent()}
      </div>
    ]
  }

  render() {
    if(isEnabled(UNSET_OLD_BOOKING_FF_NAME, false)) {
      window.location.href = BOOKING_V2_URL + "/?ac=" + this.state.ac;
    }
    if (!this.state.accountSettings) {
      return (
        <div className="center margin-top" style={{ marginTop: 150 }}>
          <CircularProgress />
        </div>
      );
    }
    const { themeColor, accountSettings } = this.state;

    const timeString = moment(this.state.appointmentDate).format('ddd[,] MMM[.] Do') + " at " + moment().hour(0).minute(0).add(this.state.appointmentSlot, 'hours').format('h:mm a');

    return (
      <MuiThemeProvider
        theme={theme(themeColor || "#3b4b52")}
      >
        <div>

          <AppBar
            position="static"
            className="center padding-vertical auto-margin margin-top"
            style={{ maxWidth: 800 }}
          >
            {accountSettings.account_logo ? <img
              className="account_logo"
              alt={accountSettings.title}
              src={accountSettings.account_logo}
            /> : ""}
            <Typography variant="h6" color="inherit" >

              {accountSettings.title}
            </Typography>
          </AppBar>
          <section
            style={{
              margin: 'auto',
              marginBottom: 5,
            }}
          >
            <Card className="auto-margin small-screen" style={{ maxWidth: "800px" }} >
              {this.state.formSent > 0 ?
                <SavingModal
                  header={this.state.formSent == 1 ? `Booking confirmed` : "Failed scheduling"}
                  body={this.state.formSent == 1 ? "You are booked for " + timeString : "We could not schedule you appointment properly. You can try again later"}
                /> :
                this.renderAllSteps()
              }
            </Card>
          </section>

        </div>
        <div className="workiz">
          <a href="https://workiz.com/features/online-booking/?utm_source=online-booking-widget&utm_medium=client_widget" target="_blank"> <img src="/logo.svg" width="95" /></a>
        </div>
      </MuiThemeProvider >

    );
  }
}

export default App;
